
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet } from 'react-helmet'
import { createBrowserRouter, RouterProvider, Routes, Route, Link, Outlet } from 'react-router-dom'

const router = createBrowserRouter([{ path: '*', Component: Root }])

function useDisablePinch() {
  React.useEffect(() => {
    if (true) {
      return
    }

    const listener = (e: WheelEvent) => {
      const isPinch = e.ctrlKey
      if (isPinch) {
        e.preventDefault()
      }
    }

    document.addEventListener('wheel', listener, { passive: false })

    return () => {
      document.removeEventListener('wheel', listener)
    }
  }, [])
}


function Root() {
  useDisablePinch()

  return (
    <ErrorBoundary
      fallbackRender={({ error }) => {
        return (
          <div>
            Error rendering React:
            <br />
            {error.message}
          </div>
        )
      }}
    >
      <React.Fragment>
  <div>
    conflict test
  </div>
</React.Fragment>
    </ErrorBoundary>
  )
}

export default function App() {
  return <RouterProvider router={router} />
}
